export class UniqueChecker<T> {
  private readonly memorySize: number;
  private readonly seen: T[];
  private index: number;
  constructor(memorySize=20) {
    this.memorySize = memorySize;
    this.seen = new Array(memorySize);
    this.index = 0;
  }

  wasSeen(value: T){
    let unique = this.seen.includes(value)
    this.seen[this.index] = value
    this.index = (this.index + 1) % this.memorySize
    return unique
  }
}